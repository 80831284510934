
import './index.less';

import {Component} from 'react';
import $, { Callbacks } from 'jquery';
import jQuery from 'jquery-easing';
import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
import { accept, checkCookie, changeUrl} from '../../common/changeUrl'
import { upperLaundry } from '../../common/utils'

import banner1 from '../../common/images/exclusive/banner1.jpg'
import banner2 from '../../common/images/exclusive/banner7.jpg'
import banner3 from '../../common/images/exclusive/banner2.jpg'
import banner4 from '../../common/images/exclusive/banner3.jpg'
import banner5 from '../../common/images/exclusive/banner6.jpg'

import n1 from '../../common/images/exclusive/n1.jpg'
import n2 from '../../common/images/exclusive/n2.png'
import n3 from '../../common/images/exclusive/n3.png'
import n4 from '../../common/images/exclusive/n4.png'
import n5 from '../../common/images/exclusive/n5.png'
import n6 from '../../common/images/exclusive/n6.png'
import n7 from '../../common/images/exclusive/n7.png'
import n8 from '../../common/images/exclusive/n8.png'
import n9 from '../../common/images/exclusive/n9.png'
import andmore from '../../common/images/exclusive/andmore.jpg'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay , A11y } from 'swiper';
// Import Swiper styles
import 'swiper/css/bundle';

import { hideMask } from '../../component/cart';
import { withRouter } from 'react-router';

const pageText = {
  section1 : {
    cover: banner1,
    title: ["N7+"],
    context: `
      <p><span class="first-letter">N</span>7+ is a membership service committed to making life better through experiences of the unexpected.
      </p><p>
      We believe that a quality lifestyle is inseparable from beauty, sports and delicacy experiences. It also requires to broaden ones horizon through art, travel and new encounters.</p><p>
      So we provide our privileged members with exclusive access to unexpected venues and events, which make life more tasty.</p>
    `,
    button: "read more"
  },
  section2 : {
    cover: [banner3,banner4,banner5],
  },
  section3 : {
    cover: banner2,
    title:["YOUR","N7+"],
    context: `
      <p>We understand the importance of your quality time with family and friends.</p>
      <p><span class="first-letter">T</span>herefore, we will do our best to give you access to priority bookings and premium services within our network of partner merchants. By doing so we want to help you save your own precious time, while allowing you to enjoy more distinguished treatments and experiences.</p>
    `,
    button: `<span class="upper-size-content none-logo">R</span>eserve online`
  },
  section4 : {
    title: ["PRIORITY"],
    n7: [n1,n2,n3,n4,n5,n6,n7,n8,n9,andmore],
  },
  section5 : {
    title: [
      `EXCLUSIVE<br/>CUSTOMER SERVICE`,`RESORT AND SPA<br/>RESERVATION`,`ADVANTAGES<br/>AND ACTIVITIES`,`MEMBERSHIP<br/>REWARDS`,`CUSTOMIZED<br/>LIFESTYLE`
    ],

  }
}


class PageExclusive extends Component {
  
  componentWillMount() {
    jQuery(".slideMask").animate({
      left: '-100vw'
    },1500,"easeInCubic",function(){
      $(".slideMask").css("left","100vw")
    })
  }

  componentDidMount(){
    if(!$(".exclusive").hasClass('active')){
      $(".exclusive").addClass('active')
    }
    $(".collection").removeClass('active')
    $(".discover").removeClass('active')
    
    window.history.scrollRestoration = 'manual'
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    hideMask()
    changeUrl()
  }
  
  render (){
    return (
      <div className="container">
        <div className='exclusive-section first'>
          <div className='exclusive-content left'>
            <div className='title'>
              <p>{pageText.section1.title[0]}</p>
            </div>
            <div className='context' dangerouslySetInnerHTML={{ __html: upperLaundry(pageText.section1.context) }}></div>
            
          </div>
          <div className='banner-d'>
            <img src={pageText.section1.cover} alt={"cover"} style={{objectPosition: "50% 30%"}}></img>
          </div>
        </div>

        <div className='banner-a'>
          <div className='banner-d'>
            <img src={pageText.section2.cover[0]} alt={"cover"}></img>
          </div>
          <div className='banner-d'>
            <img src={pageText.section2.cover[1]} alt={"cover"}></img>
          </div>
          <div className='banner-d'>
            <img src={pageText.section2.cover[2]} alt={"cover"}></img>
          </div>
        </div>
        
        <div className='exclusive-section'>
          <div className='exclusive-content middle'>
            <div className='title'>
              <p>{pageText.section4.title[0]}</p>
            </div>
            <Swiper
              modules={[Navigation, Pagination, A11y,Autoplay ]}
              slidesPerView={1}
              speed={1000}
              // pagination={{ 
              //   clickable: true,
              //   bulletClass: "bullet",
              //   bulletActiveClass: 'bulletActive',
              //  }}
              autoplay={{delay: 3000}}
              className="service-swiper"
              loop={true}
            >
              <SwiperSlide>
                <div className='service-partners-wrapper-a'>
                  <div className='service-partners-item'><img src={pageText.section4.n7[0]}></img></div>
                  <div className='service-partners-item'><img src={pageText.section4.n7[1]}></img></div>
                  <div className='service-partners-item'><img src={pageText.section4.n7[2]}></img></div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
              <div className='service-partners-wrapper-b'>
                <div className='service-partners-item'><img src={pageText.section4.n7[3]}></img></div>
                <div className='service-partners-item'><img src={pageText.section4.n7[4]}></img></div>
                <div className='service-partners-item'><img src={pageText.section4.n7[5]}></img></div>
              </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='service-partners-wrapper-c'>
                  <div className='service-partners-item'><img src={pageText.section4.n7[6]}></img></div>
                  <div className='service-partners-item'><img src={pageText.section4.n7[7]}></img></div>
                  <div className='service-partners-item'><img src={pageText.section4.n7[8]}></img></div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='service-partners-wrapper-c'>
                  <div className='service-partners-item'><img src={pageText.section4.n7[9]}></img></div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div className='exclusive-section'>
          <div className='banner-d'>
            <img src={pageText.section3.cover} alt={"cover"} style={{objectPosition: "15% 50%"}}></img>
          </div>
          <div className='exclusive-content right'>
            {/* <div className='title'>
              <p>{pageText.section3.title[0]}</p>
              <p>{pageText.section3.title[1]}</p>
            </div> */}
            <div className='context' dangerouslySetInnerHTML={{ __html: upperLaundry(pageText.section3.context) }}></div>
            {/* <Link to="/login" className="explore-more" onClick={() => {changeUrl("login")}} dangerouslySetInnerHTML={{ __html: pageText.section3.button }}></Link> */}
          </div>
        </div>
        <div className='exclusive-section guarantee'>
            <div className='context' dangerouslySetInnerHTML={{ __html: pageText.section5.title[0] }}></div>
            <div className='divider-vertical'></div>
            <div className='context' dangerouslySetInnerHTML={{ __html: pageText.section5.title[1] }}></div>
            <div className='divider-vertical'></div>
            <div className='context' dangerouslySetInnerHTML={{ __html: pageText.section5.title[2] }}></div>
            <div className='divider-vertical'></div>
            <div className='context' dangerouslySetInnerHTML={{ __html: pageText.section5.title[3] }}></div>
            <div className='divider-vertical'></div>
            <div className='context' dangerouslySetInnerHTML={{ __html: pageText.section5.title[4] }}></div>
        </div>
        
      </div>
    );
  }
}

export default withRouter(PageExclusive);
