import $ from 'jquery';
import jQuery from 'jquery-easing';
import Cookies from 'universal-cookie';
import { products } from '../component/products';

const cookies = new Cookies();

export const accept = () => {
  cookies.set('cookie','accepted','/')
  jQuery(".cookie-policy").animate({
    bottom: '-10vh',
  },300,"linear",setTimeout(()=>{$(".cookie-policy").hide()},300))
}

export const acceptMobile = () => {
  cookies.set('cookie','accepted','/')
  jQuery(".cookie-policy").animate({
    bottom: '-25vh',
  },300,"linear",setTimeout(()=>{$(".cookie-policy").hide()},300))
}

export const checkCookie = () => {
  if(cookies.get('cookie') === "accepted"){
    $(".cookie-policy").css('bottom','-10vh')
    $(".cookie-policy").hide()
  } else {
    setTimeout(()=>{
      $(".cookie-policy").show()
      jQuery(".cookie-policy").animate({
        bottom: 0
      },500,"linear")
    },1000)
    
  }
}

export const checkCookieMobile = () => {
  if(cookies.get('cookie') === "accepted"){
    $(".cookie-policy").css('bottom','-25vh')
  } else {
    setTimeout(()=>{
      $(".cookie-policy").show()
      jQuery(".cookie-policy").animate({
        bottom: 0
      },500,"linear")
    },1000)
    
  }
}

export const changeUrl = (e) => {
  var navs = ["collections","discover","exclusive"]
  
  if(navs.indexOf(e) > -1) {
    var restNavs = navs.splice(navs.indexOf(e),1)
  }

  $(".cookie-policy").css('bottom','-10vh')
  checkCookie()

  setTimeout(() => {
    window.scrollTo(0,0)
  }, 1100);
  
  var loca = window.location.href
  if (!(loca.substring(loca.length, loca.length - 1) === "/" && e === "home") && loca.indexOf(e)<0){
    
    $(`.${e}`).addClass('active')
    navs.map((item) => {
      $(`.${item}`).removeClass('active')
    })
   
    
  }
}


export const changeUrlMobile = (e) => {
  $(".AppMobile .collection-menu").css("opacity","0")
  hideMenuMobile()
  var loca = window.location.href
  var navs = ["collections","discover","exclusive"]
  
  if(navs.indexOf(e) > -1) {
    var restNavs = navs.splice(navs.indexOf(e),1)
  }

  $(".cookie-policy").css('bottom','-25vh')
  checkCookieMobile()


  if (!(loca.substring(loca.length, loca.length - 1) === "/" && e === "home") && loca.indexOf(e)<0){
    
    $(`.${e}`).addClass('active')
    navs.map((item) => {
      $(`.${item}`).removeClass('active')
    })
    
  }
}

export const checkPolicy = (policy) => {
  var navs = ["term-and-contidion","privacy-policy","shipping-policy"]

  if(navs.indexOf(policy) > -1) {
    var restNavs = navs.splice(navs.indexOf(policy),1)
  }

  $(`.${policy}`).addClass('active')
  navs.map((item) => {
    $(`.${item}`).removeClass('active')
  })
  var tops = $(`#${policy}`).offset().top - 80
  $('html').animate({scrollTop: tops}, 800)
}

export const checkPolicyMobile = (policy) => {
  var navs = ["term-and-contidion","privacy-policy","shipping-policy"]

  if(navs.indexOf(policy) > -1) {
    var restNavs = navs.splice(navs.indexOf(policy),1)
  }

  $(`.${policy}`).addClass('active')
  navs.map((item) => {
    $(`.${item}`).removeClass('active')
  })
  var tops = $(`#${policy}`).offset().top - 130
  $('html').animate({scrollTop: tops}, 800)
}

export const hideMenuMobile = () => {
  if ($(".nav-menu").hasClass("is-open")){
    jQuery(".header-menu").animate({
      top: '-240px',
      opacity: 0
    },500,"easeInOutExpo",function(){
      $(".header-menu").css("top",'-240px')
      $(".header-menu").css("opacity",0)
    })
    $(".nav-menu").removeClass("is-open")
    $(".header-mask").fadeOut(500)
  } 

}


