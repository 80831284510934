
import './index.less';
import {Component} from 'react';
import $ from 'jquery';
import jQuery from 'jquery-easing';
import DetailFR from '../../component/detailFR'
import {withRouter} from 'react-router-dom'
import { products } from '../../component/products';
import { hideMask } from '../../component/cart';
import { changeUrl } from '../../common/changeUrl';
 
class PageSunscreen extends Component {
  componentWillMount() {
    jQuery(".slideMask").animate({
      left: '-100vw'
    },1500,"easeInCubic",function(){
      $(".slideMask").css("left","100vw")
    })
  }
  
    componentDidMount(){
      if(!$(".collection").hasClass('active')){
          $(".collection").addClass('active')
      }
      $(".exclusive").removeClass('active')
      $(".discover").removeClass('active')
      window.history.scrollRestoration = 'manual'
    }
  
    componentDidUpdate(prevProps, prevState, snapshot){
      hideMask()
      changeUrl()
    }
    
    render() {
        return (
            <div className="container">
                <DetailFR product={products.FR.d}></DetailFR>
            </div>
        );
    }
}
 
export default withRouter(PageSunscreen);