
import './index.less';
import {Component} from 'react';
import $ from 'jquery';
import jQuery from 'jquery-easing';
import {withRouter} from 'react-router-dom'
import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
import { checkPolicy } from '../../common/changeUrl'
import { upperLaundryTac } from '../../common/utils'
import { hideMask } from '../../component/cart';
import { changeUrl } from '../../common/changeUrl';

const pageText = {
    menu: ["QUESTIONS LES PLUS FRÉQUENTES"],
    faq: `
        <div class="title">Qu’est ce que <span class="brand-name">NIHPLOD</span><span class="question-mark">?</span></div>
        <div class="text">La peau du dauphin a la capacité magique de se renouveler toutes les deux heures. Notre source d’inspiration vient de l’instinct animal de ‘l’version du temps’.</div>
        <div class="text">La peau sera confrontée à divers problèmes causes par le temps et l’espace dus à la pression du travail, aux mauvaises habitudes et a l’âge croissant.</div>
        <div class="text">Nous renverson ainsi ce mot d’anglais « <span class="brand-name">NIHPLOD</span> », c’est le mot <span class="brand-name">NIHPLOD</span>.</div>
        <div class="text">Grâce à la biotechnologie et à la formulation les plus avancées, nous ferons de notre mieux pour aider les gens à réaliser ‘l’inversion du temps’ dans les soins de la peau.</div>
        
        <div class="title">Pour moi, ça sert à quoi<span class="question-mark">?</span></div>
        <div class="text">Il peut renforcer l’autoréparation de la peau, hydrater, éclaircir et anti-âge. Ce sont les quatre fonctions essentielles de nos produits. </div>
        <div class="text">En fonction du produit, il améliorera efficacement la pigmentation, le jaunissement et les rougeurs de la peau causes par divers problèmes, régulera l’apparence du teint irrégulier et des ridules, et restaurera la peau a son état le plus naturel et le plus sain. </div>
        
        <div class="title">Qu-est ce qui fait <span class="brand-name">NIHPLOD</span> différente<span class="question-mark">?</span></div>
        <div class="text">Avec le développement continu de la science et de la technologie temporaire, de plus en plus de substance peuvent être utilisées dans le domaine des cosmétiques, De l’unité des ingrédients, tous ont des effets différents. </div>
        <div class="text">Cependant, la question de savoir si ces ingrédients efficaces peuvent être réellement absorbes par la peau, sans être oxydés, décomposés ou produire des effets indésirables, peut être une réelle préoccupation pour vous. </div>
        <div class="text">Afin d’obtenir plus efficacement un effet de soin de peau, <span class="brand-name">NIHPLOD</span> combine la technologie la plus avancée de nos jours, intègre la technologie du liposome et de la nano-émulsion dans le produit, favorise et répare les ingrédients actifs importants et les facteurs de croissance de la peau.</div>
        
        <div class="title">Quels sont les ingredients<span class="question-mark">?</span></div>
        <div class="text">Afin de s’adapter à différentes personnes et d’obtenir une absorption et des effets efficaces, chaque produit de <span class="brand-name">NIHPLOD</span> contient des principes actifs différents. Les ingrédients principaux sont:</div>
        <div class="text"><div class="text-radio text-radio-last"></div>Composés bioactifs des algues marines<br />Les algues sont utilisées comme médicine alternative pour les maladies cutanées. De nombreuses études ont révélé le potentiel de l’algue et son rôle principal dans les propriétés anti-oxydantes, anti-tumorales, anti-inflammatoires, anti-lipidiques, antimicrobiennes et antiallergique.</div>
        <div class="text"><div class="text-radio text-radio-last"></div>Composés actifs de bio-fermentation<br />Les composés tels que les polysaccharides obtenus par fermentation bactérienne et les herbes naturelles fermentées jouent un rôle particulier à réduire l’apparence des tissus cutanés. Ils ont une excellente performance pour favoriser la synthèse du collagène et de l’acide hyaluronique, calmer la cellules et anti-vieillir.</div>
        <div class="text"><div class="text-radio text-radio-last"></div>Une variété de probiotiques végétaux<br />Les probiotiques naturels soigneusement sélectionnés peuvent bien équilibrer les probiotiques sur la peau, et améliorer la capacité d’immunité inhérente a la peau et la fonction d’autoréparation.</div>
        <div class="text">A part des trois ingrédients communs, vous pourrez trouver également les meilleurs ingrédients actifs les plus avances pour le soin de la peau dans nos différents produits.</div>
        
        <div class="title">Qui a créé cette marque<span class="question-mark">?</span></div>
        <div class="text"><span class="brand-name">NIHPLOD</span> est le fruit d'une collaboration entre TWK Capital Management et Dr. Stefan.<br /> TWK Capital Management est une société de capital-investissement basée à Monaco, qui concentre ses investissements dans les domaines de l'art, de la banque et de la biotechnologie.<br />Le Dr Peter a obtenu son doctorat à l'Université hébraïque de Jérusalem en 1979, puis a travaillé comme professeur invité à l'Université hébraïque, à l'Université de Yale et au MIT pendant 10 ans. Son principal domaine de recherche est la formation de métabolites primaires et secondaires par diverses cellules. En 2008, le Dr. PETER a rejoint BRAND en tant que conseiller scientifique en chef.</div>
        
        <div class="title">Le produit a-t-il été prouvé cliniquement<span class="question-mark">?</span></div>
        <div class="text">Oui, Dr. Stefan et ses équipes au Royaume-Uni, en Israël et en Asie ont fait plus de 100 essais cliniques sur plus de 2,000 personnes. Les preuves et les résultats sont affichés sur tous les emballages et ici. </div>
        
        <div class="title">Comment expliquer la tarification de <span class="brand-name">NIHPLOD</span><span class="question-mark">?</span></div>
        <div class="text">Nous opérons peut-être les meilleurs produits de soin de la peau au monde. Grâce a la coopération avec Nextar Pharmaceuticals, en ajoutant la technologie liposome la plus avancée, chaque produit devient incomparable. Ce sont le résultat du plus haut niveau professionnel de la meilleure équipe scientifique, c’est egalement la meilleure experience de vie exquiese. Choisir nos produits est le meilleur investissement sur la peau et dans la mode de vie.</div>
        
        <div class="title">Est-ce que je peux partager mes produits avec mon mari/mon partenaire<span class="question-mark">?</span></div>
        <div class="text">Tous les produits peuvent être utilisés par les femmes et les hommes (ils sont fabriqués pour les femmes). Les ingrédients sélectionnés par nous sont également neutres. Leur texture est naturelle, non-grasse et efficace, donc ils peuvent également être utiliser sur la peau plus épaisses des hommes.</div>
        
        <div class="title">Les femmes enceintes peuvent-elles l’utiliser<span class="question-mark">?</span></div>
        <div class="text">Bien sûr. Après avoir effectué différents tests, notre produit ne provoquera aucun inconfort ni effets indésirable chez les femmes enceintes. Cependant, nous vous recommandons de vérifier auprès de votre médecin votre niveau de confort.</div>
        
        <div class="title">Combien de temps faudra-t-il pour voir les effets apportés par <span class="brand-name">NIHPLOD</span><span class="question-mark">?</span></div>
        <div class="text">En fonction des différents effets du produit, les résultats peuvent être vu dans 2 à 4 semaines. Nous vous suggérons fortement que vous devriez améliorer votre mode de vie plus actif et plus sain tout en profitant de nos produits. Tout cela deviendra plus confortable et beau au fil du temps.</div>
        
        <div class="title">Quelle est la durée de vie garantie de qualité et la durée de conservation du produit<span class="question-mark">?</span> </div>
        <div class="text">D'une manière générale, la durée de conservation de nos produits est de 36 mois après la date de production.<br />Ici, nous aimerions faire une déclaration spéciale : parce qu'il devrait convenir à autant de types de peau que possible, les résultats de tests répétés montrent que les conservateurs dans le produit ne peuvent jouer qu'un rôle limité, alors s'il vous plaît l'utiliser dans les 6 mois à compter de la date d'ouverture de l'emballage, afin de profiter pleinement de la sensation agréable procurée par les précieux principes actifs de divers produits ; Veuillez le garder à l'abri des températures extrêmes (froides ou chaudes) et de la lumière directe du soleil lors de l'utilisation.</div>
	`
}



var currenturl = window.location.href;



class PageModel extends Component {
    componentWillMount() {
        jQuery(".slideMask").animate({
        left: '-100vw'
        },1500,"easeInCubic",function(){
            $(".slideMask").css("left","100vw")
        })
    }
  
    componentDidMount(){
        $(".collection").removeClass('active')
        $(".exclusive").removeClass('active')
        $(".discover").removeClass('active')
        window.history.scrollRestoration = 'manual'
    }
  
    componentDidUpdate(prevProps, prevState, snapshot){
      hideMask()
      changeUrl()
    }

    
    render() {
        return (
            <div className="container tac">
                <div className='tac-content'>
                    <div className='tac-content-section'>
                        <div className='tac-title'>{pageText.menu[0]}</div>
                        <div className='tac-context' dangerouslySetInnerHTML={{ __html: upperLaundryTac(pageText.faq) }}></div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default withRouter(PageModel);