
import './index.less';

import {Component} from 'react';
import $ from 'jquery';
import jQuery from 'jquery-easing';
import { upperLaundry } from '../../common/utils'

import banner1 from '../../common/images/discover/banner1.jpg'
import banner2 from '../../common/images/discover/banner2.jpg'
import banner3 from '../../common/images/discover/banner3.jpg'
import press1 from '../../common/images/discover/press1.png'
import press2 from '../../common/images/discover/press2.png'
import press3 from '../../common/images/discover/press3.png'
import press4 from '../../common/images/discover/press4.png'
import press5 from '../../common/images/discover/press5.png'
import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
import { accept, checkCookie, changeUrlMobile} from '../../common/changeUrl'
import Header from '../../component/headerMobile';
import { hideMask } from '../../component/cartMobile';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay , A11y } from 'swiper';
// Import Swiper styles
import 'swiper/css/bundle';

import esignature from '../../common/images/discover/e-sign.png'

const pageText = {
  section1 : {
    cover: banner1,
    title: ["OUR","STORY"],
    context: `
      <p>The dolphin's skin has the magical ability to renew itself every two hours. We draw our inspiration from that animal instinct of "time reversal".</p>
      <p>As time and space change, our skin faces various problems due to work pressure, bad habits and aging.</p>
      <p>So we reversed the word "DOLPHIN", we got <span class="brand-name">NIHPLOD</span>.</p>
      <p>Through the most cutting-edge biotechnology and formulation, we try our best to help people achieve "time reversal" with skincare.</p>
    `,
    button: `<span class="upper-size-content none-logo">D</span>iscover more`
  },
  section2 : {
    cover: banner2,
    title: ["OUR","HISTORY"],
    context: `
      <p><span class="brand-name">NIHPLOD</span> was established in 2008. We use advanced biotechnology to give our clients an efficient, healthy, and refined skin care experience.</p>
      <p><span class="brand-name">NIHPLOD</span> combines liposome and nanoemulsion technology with carefully selected natural ingredients, through extensive clinical trials and research, to improve daily skincare. </p>
      <p>Our goal is to keep moving forward, to help people improve their quality of life.</p>
    `,
    sign: `<p><span class="first-letter">J</span>ohn Morrell, CEO</p>`,
    eSign: esignature
  },
  section3 : {
    cover: banner3,
    title: ["OUR","PHILOSOPHY"],
    context: `
      <p><div class="title-sm"><span class="text-radio"></span>More precious product</div>The objective of our products is to allow full skin absorption and maximize effects. The products are constantly updated and improved through collecting the best raw materials and combining the most advanced and effective scientific technologies.</p><p>
      <div class="title-sm"><span class="text-radio"></span>Better experience</div>We provide you with the most satisfying, comfortable and professional services through carefully selected supply channels and specialized companies.</p><p>
      <div class="title-sm"><span class="text-radio"></span>A more active lifestyle</div>We advocate for a healthy daily routine consisting of a moderate physical activity, a reasonable diet and a balanced mindset.</p><p>
      <div class="title-sm"><span class="text-radio"></span>Greater responsibility</div>We donate <span class="upper-size none-logo">2%</span> of our sales revenues to charities and non-profit organizations around the world, such as the United Nations Foundation, the Swiss Philanthropy Foundation, etc.</p>
      
    `,
  },
  section4 : {
    cover: press5,
    title: ["MEDIA","INFLUENCE"],
    context: `
      <p><span class="brand-name">NIHPLOD</span>'s skincare programme - worth it<span class="question-mark">?</span></p>
      <p>Absolutely yes, it's expensive but this information is priceless and gives you a targeted way to fight ageing for life.<br />It's the most empowering "beauty treatment" I've had yet.</p>
      <p style="float:right">— Robb Report</p>
    `,
    button: `<span class="upper-size-content none-logo">R</span>ead more`
  },
  section5 : {
    cover: [press4,press1,press2],
    quote: [
      
      `
      <div>To achieve the healthy, glowing skin, Scarlett Johansson worked with the luxury Monaco skincare line NIHPLOD to prep the skin.</div>
      `,
      `
      <div>NIHPLOD's step by step facial that focuses on brightening and diminishing pigmentation whilst balancing the skin.</div>
      `,
      `
      <div>With the help of new generation liposome technology, NIHPLOD solves the problem that skin can't really absorb skincare products.</div>
      `,
    ],
  }
}


const showPress = () => {
  $(".page").css("z-index",100)
  $(".press-mask").fadeIn(300)
  document.documentElement.style.overflowY = 'hidden';
}

const hidePress = () => {
  $(".page").css("z-index",90)
  $(".press-mask").fadeOut(300)
  document.documentElement.style.overflowY = 'scroll';
}

class PageDiscover extends Component {
  
  componentWillMount() {
    jQuery(".slideMask").animate({
      left: '-100vw'
    },1200,"easeInCubic",function(){
      $(".slideMask").css("left","100vw")
    })
    
    setTimeout(() => {
      window.scrollTo(0,0)
    }, 1000);
  }

  componentDidMount(){
    if(!$(".discover").hasClass('active')){
      $(".discover").addClass('active')
    }
    $(".collection").removeClass('active')
    $(".exclusive").removeClass('active')
    document.documentElement.style.overflowY = 'scroll';
    window.history.scrollRestoration = 'manual'
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    hideMask()
  }

  componentWillUnmount(){
  }

  render (){
    return (
      <div className="container">
        <div className='discover-section'>
        <div className='banner-e'>
            <img src={pageText.section1.cover} alt={"cover"}></img>
          </div>
            <div className='content'>
                <div className='title'>
                <p>{pageText.section1.title[0]}</p>
                <p> {pageText.section1.title[1]}</p>
                </div>
                <div className='context' dangerouslySetInnerHTML={{ __html: upperLaundry(pageText.section1.context) }}></div>
          </div>
        </div>
          <div className='discover-section'>
          <div className='banner-e'>
            <img src={pageText.section2.cover} alt={"cover"}></img>
          </div>
            <div className='content left'>
                <div className='title'>
                <p>{pageText.section2.title[0]}</p>
                <p> {pageText.section2.title[1]}</p>
                </div>
                <div className='context' dangerouslySetInnerHTML={{ __html: upperLaundry(pageText.section2.context) }}></div>
            </div>
          </div>
          <div className='discover-section'>
          <div className='banner-e'>
            <img src={pageText.section3.cover} alt={"cover"}></img>
          </div>
            <div className='content'>
                <div className='title'>
                <p>{pageText.section3.title[0]}</p>
                <p>{pageText.section3.title[1]}</p>
                </div>
                <div className='context' dangerouslySetInnerHTML={{ __html: upperLaundry(pageText.section3.context) }}></div>
            </div>
          </div>
          <div className='discover-section'>
          <div className='banner-e'>
            <img src={pageText.section4.cover} alt={"cover"}></img>
          </div>
            <div className='content left'>
                    <div className='title'>
                        <p>{pageText.section4.title[0]}</p>
                        <p>{pageText.section4.title[1]}</p>
                    </div>
                    <div className='context' dangerouslySetInnerHTML={{ __html: upperLaundry(pageText.section4.context) }}></div>

                </div>
          </div>
      </div>
    );
  }
}

export default PageDiscover;
