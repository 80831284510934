
import './index.less';
import {Component} from 'react';
import $ from 'jquery';
import jQuery from 'jquery-easing';
import {withRouter} from 'react-router-dom'
import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
import { checkPolicy } from '../../common/changeUrl'
import { upperLaundryTac } from '../../common/utils'
import { hideMask } from '../../component/cart';
import { changeUrl } from '../../common/changeUrl';

const pageText = {
    menu: ["FREQUENTLY ASKED QUESTIONS"],
    faq: `
    <div class="title">What is <span class="brand-name">NIHPLOD</span><span class="question-mark">?</span></div>
    <div class="text">The dolphin’s skin has the magical ability to renew itself every two hours. We draw our inspiration from that animal instinct of “time reversal”.<br />As time and space change, our skin faces various problems due to work pressure, bad habits and aging.<br />So we reversed the word "Dolphin", which is Nihplod. <br />Through the most cutting-edge biotechnology and formulas, in skin care, we will do our best to help people "reverse time".</div>
    
    <div class="title">What is it for me<span class="question-mark">?</span></div>
    <div class="text">It can strengthen the skin’s capacity to self-repair, hydrate, brighten and to face aging. These are the four essential functions of our products. <br />Depending on the product, it will effectively improve the pigmentation of the skin and deal with yellowing and redness of the skin caused by various problems. It will also regulate the appearance of uneven skin tone and fine lines, and restore the skin to its natural and healthy state.</div>
    
    <div class="title">What makes <span class="brand-name">NIHPLOD</span> different<span class="question-mark">?</span></div>
    <div class="text">With the continuous development of modern science and technology, more and more active substances are used in the field of cosmetics. From the unity of ingredients, they each have different effects.<br />However, whether these effective ingredients can be truly absorbed by the skin without being oxidized, decomposed or produced adverse reactions, may be a real concern for you.</div>
    <div class="text"><span class="brand-name">NIHPLOD</span> combines today's most cutting-edge technology, and integrates liposome and nanoemulsion technologies (Liposome and Nanoemulsion) into the product to target important active ingredients and growth factors to the skin to repair or improve, so as to Achieve the ideal skin care effect more efficiently.</div>
    
    <div class="title">What are the ingredients<span class="question-mark">?</span></div>
    <div class="text"><div class="text-radio text-radio-last"></div>Seaweed bioactive compounds<br />Seaweed is used as an alternative medicine for skin-related diseases. Many studies have revealed the potential of seaweed and its main role in antioxidant, anti-tumor, anti-inflammatory, anti-lipid, anti-microbial and anti-allergic properties.</div>
    <div class="text"><div class="text-radio text-radio-last"></div>Bio-fermentation active compounds<br />Compounds such as polysaccharides obtained through bacterial fermentation and fermented natural herbs play a special role in helping to reduce the appearance of skin tissues. They are excellent in promoting collagen and hyaluronic acid synthesis, calming and anti-aging.</div>
    <div class="text"><div class="text-radio text-radio-last"></div>A variety of plant prebiotics<br />Through carefully selected natural prebiotics, the probiotics on the skin can be well balanced, and the skin's inherent immunity and self-repair function can be enhanced.</div>
    <div class="text">In addition to the above three common ingredients, you will also find the best and most advanced active ingredients for skin care products based on our different products.</div>
    
    <div class="title">Who created this brand<span class="question-mark">?</span></div>
    <div class="text"><span class="brand-name">NIHPLOD</span> comes from a collaboration between TWK Capital Management and Dr. Stefan.<br />TWK Capital Management is a private equity firm based in Monaco, focusing its investments in the fields of art, banking and biotechnology.<br />Dr. Stefan received his Ph.D. from the Hebrew University of Jerusalem in 1979, and then worked as a visiting professor at the Hebrew University, Yale University and MIT for 10 years. His main area of research is the formation of primary and secondary metabolites by various cells. In 2008, Dr. Stefan joined <span class="brand-name">NIHPLOD</span> and took the responsibility of principal scientific consultant.
        </div>
    
    <div class="title">Has the product been clinically proven<span class="question-mark">?</span></div>
    <div class="text">Yes, through Dr.Stefan and his teams in the UK, Israel and Asia, more than 100 clinical trials have been conducted on more than 2000 people, and the proof/results are shown on all our packaging and here.</div>
    
    <div class="title">How to explain <span class="brand-name">NIHPLOD</span>'s pricing<span class="question-mark">?</span></div>
    <div class="text">We have been operating perhaps the best skin care products in the world. Through the cooperation with Nexstar Pharmaceuticals, adding the most advanced liposome technology, each product becomes unparalleled. They are the result of the highest professional level of the top scientific team , Is also the best exquisite life experience, so choosing us is your best investment in skin and lifestyle.</div>
    
    <div class="title">Can I share my products with my husband/partner<span class="question-mark">?</span></div>
    <div class="text">All products can be used by women (they are made for women) or men. We are also neutral in the choice of ingredients. Their texture is natural, non-greasy and efficient, so they can also be used on men's thicker skin use.</div>
    
    <div class="title">Can pregnant women use it<span class="question-mark">?</span></div>
    <div class="text">Of course. After various tests, our products will not cause any discomfort and adverse reactions to pregnant women; but out of caution, we recommend that you check your comfort level with your doctor.</div>
    
    <div class="title">How long will it take to see the effects of <span class="brand-name">NIHPLOD</span><span class="question-mark">?</span></div>
    <div class="text">According to the different effects of the product, you may see the results as early as 2-4 weeks. We strongly recommend that you be more active and healthier to improve your lifestyle while enjoying our products. As time goes by , All this will become more comfortable and beautiful.</div>
    
    <div class="title">What is the quality guarantee life and shelf life of the product<span class="question-mark">?</span></div>
    <div class="text">Generally speaking, the shelf life of our products is within 36 months after the date of production.<br />Here, we would like to make a special statement: because it needs to be suitable for as much different skin types as possible, the results of repeated tests show that the preservatives contained in the product can only play a limited role, so please use it within 6 months from the date of opening the package, so as to fully experience the pleasant feeling brought by the precious active ingredients of various products; Please keep it away from extreme temperature (cold or hot) and direct sunlight during use.</div>
`
}



var currenturl = window.location.href;



class PageModel extends Component {
    componentWillMount() {
        jQuery(".slideMask").animate({
        left: '-100vw'
        },1500,"easeInCubic",function(){
            $(".slideMask").css("left","100vw")
        })
    }
  
    componentDidMount(){
        $(".collection").removeClass('active')
        $(".exclusive").removeClass('active')
        $(".discover").removeClass('active')
        window.history.scrollRestoration = 'manual'
    }
  
    componentDidUpdate(prevProps, prevState, snapshot){
      hideMask()
      changeUrl()
    }

    
    render() {
        return (
            <div className="container tac">
                <div className='tac-content'>
                    <div className='tac-content-section'>
                        <div className='tac-title'>{pageText.menu[0]}</div>
                        <div className='tac-context' dangerouslySetInnerHTML={{ __html: upperLaundryTac(pageText.faq) }}></div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default withRouter(PageModel);