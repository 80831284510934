
import {Component} from 'react';
import {withRouter} from 'react-router-dom'
import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
import $ from 'jquery';
import jQuery from 'jquery-easing';
import { products } from '../products';
import './index.less';
import checkbg from '../../common/images/collection/giftboxall.jpg';
import { upperLaundry } from '../../common/utils'

var carts = {"total": 0}
var cartsFinal = Object.values(products.EN)
var totalPrice = 0
var totals = 0

export const refreshCart = (goods) => {
    totalPrice = 0
    if(goods != null){
        goods.cartItems.map((item) => {
            cartsFinal.map((jtem) => {
                if(jtem.id === item.id) {
                    jtem.num = item.num
                    totalPrice = totalPrice + item.num*jtem.specPrice
                }
            })
        })
        totals = goods.total
    }
}

const pageText = {
    cartText: {
        title: "YOUR CART",
        cancel: "CLOSE",
        empty: "Your cart is empty",
        check: "CHECK OUR PRODUCTS",
        checkBg: checkbg,
        delete: "Delete",
        total: "Total",
        checkout: "ORDER"
      },
    maxTip: `<div>The maximum qty you may purchase for each item is 3.</div>`
  }
  

export const hideMask = () => {
    $(".cart-mask").fadeOut(300)
    jQuery(".cart-wrapper,.cart-header,.cart-checkout").animate({
        right: '-100vw'
    },600,"easeInCubic",function(){
        $(".cart-wrapper,.cart-header,.cart-checkout").css("right","-100vw")
    })
    
    $(".slideMask").css("display","block")
}
  
const ifLogin = () => {
    hideMask()
}

const cut = (index) => {
    var tmp = JSON.parse(sessionStorage.getItem("cart"))
    tmp.cartItems.map((item) => {
      if(item.id === index && item.num > 0){
        item.num--
        tmp.total--
      }
      if(item.id === index && item.num === 0){
        del(item.id)
      }
    })
    sessionStorage.setItem("cart",JSON.stringify(tmp))
}

const add = (index) => {
    var tmp = JSON.parse(sessionStorage.getItem("cart"))
    
    tmp.cartItems.map((item) => {
        
    if(item.id === index && item.num === 3){
        $(`#${item.id}`).addClass('active');
        setTimeout(
            function () {
                $(`#${item.id}`).removeClass('active')
            },1200
        )
    } else if(item.id === index && item.num <= 2){
        item.num++
        tmp.total++
      }
    })
    sessionStorage.setItem("cart",JSON.stringify(tmp))
}

const del = (index) => {
    var tmp = JSON.parse(sessionStorage.getItem("cart"))
    tmp.cartItems.map((item) => {
      if(item.id === index){
        tmp.total = tmp.total - item.num
        item.num = 0
      }
    })
    sessionStorage.setItem("cart",JSON.stringify(tmp))
}

const addMessage = () => {
    $(".personalized-message").toggleClass("is-opened")
}

class Cart extends Component {
    componentWillMount(){
        carts = JSON.parse(sessionStorage.getItem("cart"))
        refreshCart(carts)
    }
    
    componentDidUpdate(){
        carts = JSON.parse(sessionStorage.getItem("cart"))
        refreshCart(carts)
    }
    
    componentDidMount() {
        carts = JSON.parse(sessionStorage.getItem("cart"))
        refreshCart(carts)
        window.addEventListener("setItemEvent", (e) => {
            if (e.key = "cart") {
                var _this = sessionStorage.getItem("cart");
                if (_this !== e.newValue) {
                    if (e.newValue) {
                        this.setState({
                            show: e.newValue
                        })
                    }
                    carts = JSON.parse(e.newValue)
                    refreshCart(carts)
                }
            } 
        })
    }

    componentWillUnmount(){
        window.removeEventListener("setItemEvent", (e) => {
            if (e.key = "cart") {
                var _this = sessionStorage.getItem("cart");
                if (_this !== e.newValue) {
                    if (e.newValue) {
                        this.setState({
                            show: e.newValue
                        })
                    }
                }
            } 
        })
    }
    
 
  render() {
    return (
        <div className='cart-wrapper'>
            {
                totals === 0 ? 
                    <div className='cart-list cart-list-blank'>
                        <div className='cart-header'>
                            <div className='cart-title'>{pageText.cartText.title}</div>
                            <div className='cancel' onClick={() => hideMask()}>{pageText.cartText.cancel}</div>
                        </div>
                        <div className='cart-body'>
                            <div className='cart-empty'>{pageText.cartText.empty}</div>
                            <div className='cart-empty'>
                                <img src={pageText.cartText.checkBg}></img>
                                <Link to="/collection" className="check-our-products" onClick={()=>{hideMask()}}>{pageText.cartText.check}</Link>
                            </div>
                        </div>
                    </div>
                 : 
                    <div className='cart-list'>
                        <div className='cart-header'>
                            <div className='cart-title'>{pageText.cartText.title} ({carts.total})</div>
                            <div className='cancel' onClick={() => hideMask()}>{pageText.cartText.cancel}</div>
                        </div>
                        <div className='cart-items'>
                            {cartsFinal.map((item) => {
                                if(item.num > 0){
                                    return (
                                        <div>
                                        <div className='cart-item-wrapper'>
                                            <div className='cart-item-cover'>{
                                                    item.id === 'NBSH92101B' || item.id === 'NBSH92102B' ?
                                                    <img src={item.details[1]}></img>
                                                    :
                                                    <img src={item.details[0]}></img>
                                                }
                                            </div>
                                            <div className='cart-item-specs'>
                                                <div className='cart-item-name'>{item.name}</div>
                                                {
                                                    item.id === 'NBSH921030' ?
                                                    <div className='mask-type'>Lyocell Fiber</div>
                                                    :
                                                    <span></span>
                                                }
                                                {
                                                    item.id === 'NBSH921029' ?
                                                    <div className='mask-type'>Bio-Cellulose</div>
                                                    :
                                                    <span></span>
                                                }
                                                {
                                                    item.id === 'NBSH921037' ?
                                                    <div className='mask-type'>SPF 30</div>
                                                    :
                                                    <span></span>
                                                }
                                                {
                                                    item.id === 'NBSH921038' ?
                                                    <div className='mask-type'>SPF 50</div>
                                                    :
                                                    <span></span>
                                                }
                                                {
                                                    item.id === 'NBSH92101B' ?
                                                    <div className='mask-type'>Gift Box #2</div>
                                                    :
                                                    <span></span>
                                                }
                                                {
                                                    item.id === 'NBSH92102B' ?
                                                    <div className='mask-type'>Gift Box #3</div>
                                                    :
                                                    <span></span>
                                                }
                                                <div className={item.cato === 'mask' ? 'cart-item-vol-price mask-type-price' : 'cart-item-vol-price'}>
                                                    <div className='cart-item-vol'>{item.specVol}</div>
                                                    <div className='cart-item-price'>{item.specPrice}<span className='euro-mark'>€</span></div>
                                                </div>
                                                <div className={item.cato === 'mask' ? 'cart-item-qty-panel mask-type-panel' : 'cart-item-qty-panel'}>
                                                    <div className='cart-item-qty'>
                                                        <div className='qty-btn min-qty' onClick={() => cut(item.id)}>-</div>
                                                        <div className='qty-num'>{item.num}</div>
                                                        <div className='qty-btn add-qty' onClick={() => add(item.id)}>+</div>
                                                    </div>
                                                    <div className='cart-item-qty'>
                                                        <div className='del-qty' onClick={() => del(item.id)} dangerouslySetInnerHTML={{ __html: pageText.cartText.delete}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className='max-tip-m' id={item.id} dangerouslySetInnerHTML={{ __html: upperLaundry(pageText.maxTip)}}></div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <div className='cart-checkout'>
                            <div className='personalized-message'>
                                <div className='checkbox_container' onClick={() => addMessage()}>
                                    <input type={"checkbox"} className='noteCheckbox' name='noteCheckbox' value></input>
                                    <label for="noteCheckbox" className='checkboxlabel'>
                                        <span class="checkbox_box" aria-hidden="true"></span>
                                        Add a personalized message to attach to your purchase.
                                    </label>
                                </div>
                                <textarea name="note" class="note_textarea" maxlength="150" placeholder="Votre message"></textarea>
                            </div>
                            <div className='cart-amount'>
                                <div>{pageText.cartText.total}</div>
                                <div>{totalPrice}<span className='euro-mark'>€</span></div>
                            </div>
                            <Link to="/login" onClick={() => ifLogin()}>
                                <div className='check-out-btn'>
                                    <div>{pageText.cartText.checkout}</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                
            }
            

            
        </div>
    );
  }
}
 
export default withRouter(Cart);
