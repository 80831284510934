
import './index.less';

import {Component} from 'react';
import $, { Callbacks } from 'jquery';
import jQuery from 'jquery-easing';
import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
import { accept, checkCookie, changeUrl} from '../../common/changeUrl'
import { upperLaundry } from '../../common/utils'

import banner1 from '../../common/images/exclusive/banner1.jpg'
import banner2 from '../../common/images/exclusive/banner7.jpg'
import banner3 from '../../common/images/exclusive/banner2.jpg'
import banner4 from '../../common/images/exclusive/banner3.jpg'
import banner5 from '../../common/images/exclusive/banner6.jpg'

import n1 from '../../common/images/exclusive/n1.jpg'
import n2 from '../../common/images/exclusive/n2.png'
import n3 from '../../common/images/exclusive/n3.png'
import n4 from '../../common/images/exclusive/n4.png'
import n5 from '../../common/images/exclusive/n5.png'
import n6 from '../../common/images/exclusive/n6.png'
import n7 from '../../common/images/exclusive/n7.png'
import n8 from '../../common/images/exclusive/n8.png'
import n9 from '../../common/images/exclusive/n9.png'
import andmore from '../../common/images/exclusive/andmore.jpg'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay , A11y } from 'swiper';
// Import Swiper styles
import 'swiper/css/bundle';
import { hideMask } from '../../component/cart';

const pageText = {
  section1 : {
    cover: banner1,
    title: ["N7+"],
    context: `
      <p><span class="first-letter">N</span>7+ est un service réservé aux membres, pour enrichir leur vie par des expériences inattendues. </p><p>Nous pensons qu'un mode de vie de qualité est indissociable de la beauté, du sport et de la délicatesse. Il nécessite également d'élargir son horizon à travers l'art, les voyages et les nouvelles rencontres.
      </p><p>
      Nous offrons donc à nos membres privilégiés un accès exclusif à des lieux et des événements inattendus, qui rendent la vie plus savoureuse.
      </p>
    `,
    button: "read more"
  },
  section2 : {
    cover: [banner3,banner4,banner5],
  },
  section3 : {
    cover: banner2,
    title:["VOTRE","N7+"],
    context: `
      <p>Nous comprenons l'importance du temps que vous passez avec votre famille et vos amis.</p>
      <p>C'est pourquoi nous faisons tout notre possible pour vous donner accès à des réservations prioritaires et à des services premium au sein de notre réseau de commerçants partenaires. Nous voulons ainsi vous aider à gagner du temps précieux, tout en vous permettant de profiter de soins et d'expériences distingués.</p>
    `,
    button: `<span class="upper-size-content none-logo">R</span>éservez en ligne`
  },
  section4 : {
    title: ["LA PRIORITÉ"],
    n7: [n1,n2,n3,n4,n5,n6,n7,n8,n9,andmore],
  },
  section5 : {
    title: [
      `SERVICE<br/>CLIENT EXCLUSIF`,`RÉSERVATION DE STATIONS<br/>ET DE SPAS`,`AVANTAGES<br/>ET ACTIVITÉS`,`RÉCOMPENSES POUR<br/>LES MEMBRES`,`PERSONNALISÉ POUR<br/>VOTRE STYLE DE VIE`
    ],

  }
}


class PageExclusive extends Component {
  
  componentWillMount() {
    jQuery(".slideMask").animate({
      left: '-100vw'
    },1500,"easeInCubic",function(){
      $(".slideMask").css("left","100vw")
    })
  }

  componentDidMount(){
    if(!$(".exclusive").hasClass('active')){
      $(".exclusive").addClass('active')
    }
    $(".collection").removeClass('active')
    $(".discover").removeClass('active')
    
    window.history.scrollRestoration = 'manual'
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    hideMask()
    changeUrl()
  }

  
  
  render (){
    return (
      <div className="container">
        <div className='exclusive-section first'>
          <div className='exclusive-content left'>
            <div className='title'>
              <p>{pageText.section1.title[0]}</p>
            </div>
            <div className='context' dangerouslySetInnerHTML={{ __html: upperLaundry(pageText.section1.context) }}></div>
            
          </div>
          <div className='banner-d'>
            <img src={pageText.section1.cover} alt={"cover"} style={{objectPosition: "50% 30%"}}></img>
          </div>
        </div>

        <div className='banner-a'>
          <div className='banner-d'>
            <img src={pageText.section2.cover[0]} alt={"cover"}></img>
          </div>
          <div className='banner-d'>
            <img src={pageText.section2.cover[1]} alt={"cover"}></img>
          </div>
          <div className='banner-d'>
            <img src={pageText.section2.cover[2]} alt={"cover"}></img>
          </div>
        </div>
        
        <div className='exclusive-section'>
          <div className='exclusive-content middle'>
            <div className='title'>
              <p>{pageText.section4.title[0]}</p>
            </div>
            <Swiper
              modules={[Navigation, Pagination, A11y,Autoplay ]}
              slidesPerView={1}
              speed={1000}
              // pagination={{ 
              //   clickable: true,
              //   bulletClass: "bullet",
              //   bulletActiveClass: 'bulletActive',
              //  }}
              autoplay={{delay: 3000}}
              className="service-swiper"
              loop={true}
            >
              <SwiperSlide>
                <div className='service-partners-wrapper-a'>
                  <div className='service-partners-item'><img src={pageText.section4.n7[0]}></img></div>
                  <div className='service-partners-item'><img src={pageText.section4.n7[1]}></img></div>
                  <div className='service-partners-item'><img src={pageText.section4.n7[2]}></img></div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
              <div className='service-partners-wrapper-b'>
                <div className='service-partners-item'><img src={pageText.section4.n7[3]}></img></div>
                <div className='service-partners-item'><img src={pageText.section4.n7[4]}></img></div>
                <div className='service-partners-item'><img src={pageText.section4.n7[5]}></img></div>
              </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='service-partners-wrapper-c'>
                  <div className='service-partners-item'><img src={pageText.section4.n7[6]}></img></div>
                  <div className='service-partners-item'><img src={pageText.section4.n7[7]}></img></div>
                  <div className='service-partners-item'><img src={pageText.section4.n7[8]}></img></div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='service-partners-wrapper-c'>
                  <div className='service-partners-item'><img src={pageText.section4.n7[9]}></img></div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div className='exclusive-section'>
          <div className='banner-d'>
            <img src={pageText.section3.cover} alt={"cover"} style={{objectPosition: "15% 50%"}}></img>
          </div>
          <div className='exclusive-content right'>
            {/* <div className='title'>
              <p>{pageText.section3.title[0]}</p>
              <p>{pageText.section3.title[1]}</p>
            </div> */}
            <div className='context' dangerouslySetInnerHTML={{ __html: upperLaundry(pageText.section3.context) }}></div>
            {/* <Link to="/login" className="explore-more" onClick={() => {changeUrl("login")}} dangerouslySetInnerHTML={{ __html: pageText.section3.button }}></Link> */}
          </div>
        </div>
        <div className='exclusive-section guarantee'>
            <div className='context' dangerouslySetInnerHTML={{ __html: pageText.section5.title[0] }}></div>
            <div className='divider-vertical'></div>
            <div className='context' dangerouslySetInnerHTML={{ __html: pageText.section5.title[1] }}></div>
            <div className='divider-vertical'></div>
            <div className='context' dangerouslySetInnerHTML={{ __html: pageText.section5.title[2] }}></div>
            <div className='divider-vertical'></div>
            <div className='context' dangerouslySetInnerHTML={{ __html: pageText.section5.title[3] }}></div>
            <div className='divider-vertical'></div>
            <div className='context' dangerouslySetInnerHTML={{ __html: pageText.section5.title[4] }}></div>
        </div>
        
      </div>
    );
  }
}

export default PageExclusive;
