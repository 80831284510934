import {Component} from 'react';
import {withRouter} from 'react-router-dom'
import $ from 'jquery';
 
class ScrollToTop extends Component {
    
  componentDidUpdate(prevProps, prevState, snapshot) {
    var currenturl = window.location.href;
      if (currenturl.indexOf('#') < 0 && this.props.location.pathname!==prevProps.location.pathname){
         window.scrollTo(0,0)
       // $('html').delay(1500).animate({scrollTop: 0}, 600)
    }
  }
 
  render() {
    return (
      this.props.children
    );
  }
}
 
export default withRouter(ScrollToTop);