
import './index.less';
import {Component} from 'react';
import $ from 'jquery';
import jQuery from 'jquery-easing';
import Detail from '../../component/detailMobile'
import {withRouter} from 'react-router-dom'
import { products } from '../../component/products';
import { hideMask } from '../../component/cartMobile';
import { changeUrlMobile } from '../../common/changeUrl';
 
class PageMask extends Component {
  componentWillMount() {
    jQuery(".slideMask").animate({
      left: '-100vw'
    },1200,"easeInCubic",function(){
      $(".slideMask").css("left","100vw")
    })
    setTimeout(() => {
      window.scrollTo(0,0)
    }, 900);
  }
    componentDidMount(){
      if(!$(".collection").hasClass('active')){
          $(".collection").addClass('active')
      }
      $(".exclusive").removeClass('active')
      $(".discover").removeClass('active')
      window.history.scrollRestoration = 'manual'
    }
  
    componentDidUpdate(prevProps, prevState, snapshot){
      hideMask()
    }
    
    render() {
        return (
            <div className="container">
                <Detail product={products.EN.e}></Detail>
            </div>
        );
    }
}
 
export default withRouter(PageMask);