import React, { useState } from 'react';
import './index.less';
import {Component} from 'react';
import $ from 'jquery';
import jQuery from 'jquery-easing';
import {withRouter} from 'react-router-dom'
import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
import { changeUrlMobile, checkPolicy } from '../../common/changeUrl'
import * as Request from '../../common/request';
import { upperLaundryTac } from '../../common/utils'
import { hideMask } from '../../component/cartMobile';

const pageText = {
    menu: ["CONTACT"],
    intro:  `
    <div class="text"><span class="brand-name">NIHPLOD</span> prides itself on having best in class customer service and support. Every concern or feedback is valuable and appresciated. Please use the short form below to send us a message, and we will reply as soon as possible.</div>
    `,
    text: {
        SUBMIT: 'SUBMIT',
        FIRST_NAME: 'FIRST NAME',
        EMAILADDRESS: 'EMAIL ADDRESS',
        LAST_NAME: 'LAST NAME',
        LEAVE_MSG: 'LEAVE YOUR MESSAGE HERE',
        BUTTON:"SUBMIT",
        ERR_FIRST_NAME: 'First name is required.',
        ERR_EMAILADDRESS: 'Email address is required.',
        ERR_LAST_NAME: 'Last name is required.',
        ERR_LEAVE_MSG: 'Please leave your message here.',
        ERR_EMAIL_FORMAT: 'Wrong email format.',
        SUCCESSTIP: `Submitted Successfully.<br />We will contact you soon.`
    },
}


const FormBody = (props) => {
    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ last, setLast] = useState('');
    const [ msg, setMessage ] = useState('');
    
    const login7 = () => {
        const EMAIL_PATTERN = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
        $(".form-btn").addClass("disabled")
        if ($(".form-name").val() === ""){
            $(".blank-name").fadeIn(300,function () {
                setTimeout(
                function () {
                    $(".form-btn").removeClass("disabled");
                    $(".blank-name").fadeOut(300)
                },1000
                )
            });
        } else if ($(".form-last").val() === ""){
            $(".blank-last").fadeIn(300,function () {
            setTimeout(
                function () {
                    $(".form-btn").removeClass("disabled")
                    $(".blank-last").fadeOut(300)
                },1000
            )
            });
        }  else if ($(".form-email").val() === ""){
            $(".blank-email").fadeIn(300,function () {
            setTimeout(
                function () {
                    $(".form-btn").removeClass("disabled")
                    $(".blank-email").fadeOut(300)
                },1000
            )
            });
        } else if(!EMAIL_PATTERN.test(email)){
            $(".wrong-email").fadeIn(300,function () {
              setTimeout(
                function () {
                    $(".form-btn").removeClass("disabled")
                    $(".wrong-email").fadeOut(300)
                },1000
              )
            });
          } else if ($(".form-msg").val() === ""){
            $(".blank-msg").fadeIn(300,function () {
            setTimeout(
                function () {
                    $(".form-btn").removeClass("disabled")
                    $(".blank-msg").fadeOut(300)
                },1000
            )
            });
        } else {
            Request.post({
            url: '/u/contact',
            data: {
                'first_name':name,
                email,
                'last_name':last,
                msg,
            }
            }).then(res => {
            $(".blank-acct").delay(1500).fadeIn(300,function () {
                setTimeout(
                    function () {
                        $(".form-btn").removeClass("disabled")
                        $(".blank-acct").fadeOut(300)
                    },1000
                )
            });
        });
      }
    };

    return (
        <div className='form-wrapper'>
            <div className='input-wrapper'>
                <input placeholder={pageText.text.FIRST_NAME} onChange={e => {
                        setName(e.target.value);
                    }} className='form-name'></input>
                </div>
                <div className='input-wrapper'>
                    <input placeholder={pageText.text.LAST_NAME} onChange={e => {
                        setLast(e.target.value);
                    }} className='form-last'></input>
                </div>
                <div className='input-wrapper'>
                    <input placeholder={pageText.text.EMAILADDRESS} onChange={e => {
                        setEmail(e.target.value);
                    }} className='form-email'></input>
                </div>
                <div className='input-wrapper'>
                    <input placeholder={pageText.text.LEAVE_MSG} onChange={e => {
                        setMessage(e.target.value);
                    }} className='form-msg'></input>
                </div>
            <div className='form-btn' onClick={() => login7()}>{pageText.text.SUBMIT}</div>
        </div>
    )
}


class PageModel extends Component {
    componentWillMount() {
        jQuery(".slideMask").animate({
            left: '-100vw'
          },1200,"easeInCubic",function(){
            $(".slideMask").css("left","100vw")
          })
          setTimeout(() => {
            window.scrollTo(0,0)
          }, 900);
    }
  
    componentDidMount(){
        $(".collection").removeClass('active')
        $(".exclusive").removeClass('active')
        $(".discover").removeClass('active')
          
  
        window.history.scrollRestoration = 'manual'
    }
  
    componentDidUpdate(prevProps, prevState, snapshot){
      hideMask()
    }

    
    render() {
        return (
            <div className="container tac forms">
                <div className='blank-input blank-name'>{pageText.text.ERR_FIRST_NAME}</div>
                <div className='blank-input blank-email'>{pageText.text.ERR_EMAILADDRESS}</div>
                <div className='blank-input blank-last'>{pageText.text.ERR_LAST_NAME}</div>
                <div className='blank-input blank-msg'>{pageText.text.ERR_LEAVE_MSG}</div>
                <div className='blank-input blank-acct' dangerouslySetInnerHTML={{ __html: pageText.text.SUCCESSTIP }}></div>
                <div className='blank-input wrong-email'>{pageText.text.ERR_EMAIL_FORMAT}</div>
                
                <div className='tac-menu'></div>
                <div className='tac-content'>
                    <div className='tac-content-section'>
                        <div className='tac-title'>{pageText.menu[0]}</div>
                        <div className='tac-context' dangerouslySetInnerHTML={{ __html: upperLaundryTac(pageText.intro) }}></div>
                    </div>
                    <FormBody></FormBody>
                </div>
            </div>
        );
    }
}
 
export default withRouter(PageModel);