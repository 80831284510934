import {Component} from 'react';
import {withRouter} from 'react-router-dom'
import $ from 'jquery';
import jQuery from 'jquery-easing';
import './index.less';
import { upperLaundryProduct } from '../../common/utils'

import { changeUrl } from '../../common/changeUrl';
import { BrowserRouter as Router, Route, Link} from 'react-router-dom'

import { products } from '../products';

const viewImg = (i) => {
  var index = [0,1,2]
  var newarr = index.splice(i,1)
  
  $(`.product-img-${i}`).fadeIn(200)
  index.map((item) => {
    $(`.product-img-${item}`).fadeOut(200)
  })
    
}

const viewDetail = (detail) => {
  $(`.product-${detail} .product-specs-chevron, .product-${detail} .product-specs-detail`).toggleClass('active')
}

const pageText = {
  btnTitle: "ADD TO CART",
  specs: ["benefit","ingredients","official notes"],
  feature: {
    a : `skinsolutions<br/>for<br/>all skins`,
    b : `dermatologist<br/>-<br/>tested`,
    c : `good<br/>manufacturing<br/>practice`,
    d : `free shipping<br/>on<br/>membership orders`,
  }
}
 
const addToCart = (index) => {
  var cart = JSON.parse(sessionStorage.getItem("cart"))
  cart.cartItems.map((item) => {
    if(item.id === index && item.num === 3){
      $(`#${item.id}`).delay(500).fadeIn(300,function () {
          setTimeout(
              function () {
                  $(`#${item.id}`).fadeOut(300)
              },1200
          )
      });
    } else if(item.id === index && item.num <= 2){
      item.num++
      cart.total++
    }
  })
  sessionStorage.setItem("cart",JSON.stringify(cart))
  $(".cart-mask").fadeIn(500)
  jQuery(".cart-list").animate({
    right: '0'
  },500,"easeInOutExpo",function(){
    $(".cart-list").css("right",0)
  })
  setTimeout(() => {
    
    $(".page").addClass("scrollStop")
  }, 600);
}

class PageDetail extends Component {

  constructor(props) {
    super(props);
      this.state = {
        ptype : this.props.product.ptype,
        otype : this.props.product.otype,
        product : this.props.product,
        ptype2 : 'SPF 30',
        otype2 :'SPF 50',
        note: products.notes,
        ptype3 : products.EN.i.types,
        otype3 : products.EN.j.types,
      };
  }

  chooseType = () => {
    $(".mask-type-chevron").toggleClass("active")
    $(".type-option").toggle(200)
  }

  chooseType2 = () => {
    $(".mask-type-chevron").toggleClass("active")
    $(".type-option").toggle(200)
  }

  chooseType3 = () => {
    $(".mask-type-chevron").toggleClass("active")
    $(".type-option").toggle(200)
  }

  pickType = (e) => {
    $(".mask-type-chevron").toggleClass("active")
    $(".type-option").toggle(200)
    if(e === 'Lyocell Fiber'){
      this.setState({
        ptype : 'Lyocell Fiber',
        otype :'Bio-Cellulose',
        product : products.EN.g
      });
    } else if (e === 'Bio-Cellulose'){
      this.setState({
        ptype : 'Bio-Cellulose',
        otype :'Lyocell Fiber',
        product : products.EN.a
      });
      
    }
  }
  
  pickType2 = (e) => {
    $(".mask-type-chevron").toggleClass("active")
    $(".type-option").toggle(200)
    if(e === 'SPF 30'){
      this.setState({
        ptype2 : 'SPF 30',
        otype2 :'SPF 50',
        product : products.EN.d
      });
    } else if (e === 'SPF 50'){
      this.setState({
        ptype2 : 'SPF 50',
        otype2 :'SPF 30',
        product : products.EN.h
      });
      
    }
  }

  pickType3 = (e) => {
    $(".mask-type-chevron").toggleClass("active")
    $(".type-option").toggle(200)
    if(e === products.EN.i.types){
      this.setState({
        ptype3 : products.EN.i.types,
        otype3 : products.EN.j.types,
        product : products.EN.i
      });
    } else if (e === products.EN.j.types){
      this.setState({
        ptype3 : products.EN.j.types,
        otype3 :products.EN.i.types,
        product : products.EN.j
      });
      
    }
  }

  render() {
    const {ptype,otype,product,ptype2,otype2,note,ptype3,otype3} = this.state
    return (
      <div className='product-detail'>
          <div className='product-photo'>
              <div className='cover'>
                {product.details.map((item,index) => (
                    <div className={`product-img product-img-${index}`}>
                        <img src={item} alt={product.specName}></img>
                    </div>
                ))}
            </div>
            <div className='thumb'>
                {product.details.map((item,index) => (
                    <div className='thumb-img' onClick={() => viewImg(index)}>
                        <img src={item} alt={product.specName}></img>
                    </div>
                ))}
            </div>
          </div>

          <div className='product-specs'>
          <div className='back-btn'>
          <Link to={this.props.location.source != null ? "/" : "collection"} onClick={() => changeUrl(this.props.location.source != null ? "home" : "collection")}>back</Link></div>
            <div className='product-name'>{product.name}</div>
            {
              product.cato == "mask" ?
              <div>
                <div className='choose-type' onClick={() => this.chooseType()}>
                  <div>{ptype}<div className='mask-type-chevron'></div></div>
                </div>
                <div className='type-option' onClick={() => this.pickType(otype)}>{otype}</div>
              </div> 
              :
              <div></div>
            }
            {
              product.cato == "giftbox" ?
              <div>
                <div className='choose-type' onClick={() => this.chooseType3()}>
                  <div>{ptype3}<div className='mask-type-chevron'></div></div>
                </div>
                <div className='type-option' onClick={() => this.pickType3(otype3)}>{otype3}</div>
              </div> 
              :
              <div></div>
            }
            {/* {
              product.short == "sunscreen" ?
              <div>
                <div className='choose-type' onClick={() => this.chooseType2()}>
                  <div>{ptype2}<div className='mask-type-chevron'></div></div>
                </div>
                <div className='type-option' onClick={() => this.pickType2(otype2)}>{otype2}</div>
              </div> 
              :
              <div></div>
            } */}
            <div className='product-info'>
              <div className='product-vol'>{product.specVol}</div>
              <div className='product-price'>{product.specPrice}<span className='euro-mark'>€</span></div>
            </div>
            <div className='add-to-cart' onClick={() => addToCart(product.id)}>{pageText.btnTitle}</div>
            <div className='divider' ></div>
            {
              product.cato == "giftbox" ?
              <div className='product-ingredients'>
                <div className='product-specs-menu'>
                  <div className='product-specs-title'>{pageText.specs[1]}</div>
                </div>
                <div className='product-specs-detail active' dangerouslySetInnerHTML={{ __html: upperLaundryProduct(product.ingredients) }}></div>
              </div>
              :
              <div>
            <div className='product-benefit'>
              <div className='product-specs-menu' onClick={() => {viewDetail("benefit")}}>
                <div className='product-specs-title'>{pageText.specs[0]}</div>
                <div className='product-specs-chevron active'></div>
              </div>
              <div className='product-specs-detail active' dangerouslySetInnerHTML={{ __html: upperLaundryProduct(product.benefit) }}></div>
            </div>
            <div className='divider'></div>
              <div className='product-ingredients'>
                <div className='product-specs-menu' onClick={() => {viewDetail("ingredients")}}>
                  <div className='product-specs-title'>{pageText.specs[1]}</div>
                  <div className='product-specs-chevron' ></div>
                </div>
                <div className='product-specs-detail' dangerouslySetInnerHTML={{ __html: upperLaundryProduct(product.ingredients) }}></div>
              </div>
              <div className='divider'></div>
              <div className='product-offcial-notes'>
                <div className='product-specs-menu' onClick={() => {viewDetail("offcial-notes")}}>
                  <div className='product-specs-title'>{pageText.specs[2]}</div>
                  <div className='product-specs-chevron' ></div>
                </div>
                <div className='product-specs-detail' dangerouslySetInnerHTML={{ __html: upperLaundryProduct(product.officialNotes) }}></div>
              </div>
            </div>
            }
            <div className='divider'></div>
            {/* <div className='product-specs-note' dangerouslySetInnerHTML={{ __html: upperLaundryProduct(note.EN.one) }}></div> */}
            {/* <div className='feature'>
              <div className='feature-item' dangerouslySetInnerHTML={{ __html: pageText.feature.a }}></div>
              <div className='feature-item' dangerouslySetInnerHTML={{ __html: pageText.feature.b}}></div>
              <div className='feature-item' dangerouslySetInnerHTML={{ __html: pageText.feature.c }}></div>
              <div className='feature-item' dangerouslySetInnerHTML={{ __html: pageText.feature.d }}></div>
            </div> */}
          </div>
      </div>
    );
  }
}
 
export default withRouter(PageDetail);