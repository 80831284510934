


export const upperLaundry = (field) => {
    const stringArray = field.split('')
      let newField = field
      var n = 0
      // stringArray.forEach(t => {
      //   if (/[A-Z]/.test(t)) { 
      //     newField = newField.replace(t, `<span class="upper-size-content none-logo">${t}</span>`)
      //      n++
      //  }
      // })
      for (var i=0;i<stringArray.length;i++){
        if (/[A-Z0-9]/.test(stringArray[i])){
          stringArray[i] = '<span class="upper-size-content none-logo">' + stringArray[i] +'</span>'
          n++
        }
      }
      return stringArray.join("")
}

export const upperLaundryTac = (field) => {
  const stringArray = field.split('')
    let newField = field
    var n = 0
    // stringArray.forEach(t => {
    //   if (/[A-Z]/.test(t)) { 
    //     newField = newField.replace(t, `<span class="upper-size-content none-logo">${t}</span>`)
    //      n++
    //  }
    // })
    for (var i=0;i<stringArray.length;i++){
      if (/[A-Z0-9]/.test(stringArray[i])){
        stringArray[i] = '<span class="upper-size-content tac-upper">' + stringArray[i] +'</span>'
        n++
      }
    }
    return stringArray.join("")
}

export const upperLaundryProduct = (field) => {
  const stringArray = field.split('')
    let newField = field
    var n = 0
    // stringArray.forEach(t => {
    //   if (/[A-Z]/.test(t)) { 
    //     newField = newField.replace(t, `<span class="upper-size-content none-logo">${t}</span>`)
    //      n++
    //  }
    // })
    for (var i=0;i<stringArray.length;i++){
      if (/[A-Z0-9]/.test(stringArray[i])){
        stringArray[i] = '<span class="product-upper">' + stringArray[i] +'</span>'
        n++
      }
    }
    return stringArray.join("")
}

export const upperLaundryCookie = (field) => {
  if(field != undefined){
    const stringArray = field.split('')
    let newField = field
    var n = 0
    // stringArray.forEach(t => {
    //   if (/[A-Z]/.test(t)) { 
    //     newField = newField.replace(t, `<span class="upper-size-content none-logo">${t}</span>`)
    //      n++
    //  }
    // })
    for (var i=0;i<stringArray.length;i++){
      if (/[A-Z0-9]/.test(stringArray[i])){
        stringArray[i] = '<span class="upper-size-content cookie-upper">' + stringArray[i] +'</span>'
        n++
      }
    }
    return stringArray.join("")
  }
  
}