import React, { useState } from 'react';
import './index.less';
import {Component} from 'react';
import $ from 'jquery';
import jQuery from 'jquery-easing';
import {withRouter} from 'react-router-dom'
import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
import { checkPolicy,changeUrlMobile } from '../../common/changeUrl'
import * as Request from '../../common/request';
import { upperLaundryTac } from '../../common/utils'
import { hideMask } from '../../component/cartMobile';

const pageText = {
    text: {
        LOGIN: 'CONNEXION',
        SUBMIT: 'CONNEXION',
        EMAILADDRESS: 'Adresse E-mail',
        PASSWORD: 'Mot de passe',
        BUTTON:"CONNEXION",
        INTEREST:`Intéressé par notre adhésion<span class="question-mark">?</span>`,
        MEMBERSHIP: "CLIQUEZ ICI",
        ERR_EMAILADDRESS: 'Adresse E-mail est requis.',
        ERR_PASSWORD: 'Mot de passe est requis.',
        ERR_EMAIL_FORMAT: 'Adresse e-mail invalide',
        tipTxt: `S'il vous plaît, mettez une adresse email valide.`,
    },
}


const FormBody = (props) => {
    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    
    const login8 = () => {
        const EMAIL_PATTERN = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
        $(".form-btn").addClass("disabled")
        if ($(".form-email").val() === ""){
            $(".blank-email").fadeIn(300,function () {
            setTimeout(
                function () {
                    $(".form-btn").removeClass("disabled")
                    $(".blank-email").fadeOut(300)
                },1000
            )
            });
        } else if(!EMAIL_PATTERN.test(email)){
            $(".wrong-email").fadeIn(300,function () {
              setTimeout(
                function () {
                    $(".form-btn").removeClass("disabled")
                    $(".wrong-email").fadeOut(300)
                },1000
              )
            });
          } else if ($(".form-password").val() === ""){
            $(".blank-password").fadeIn(300,function () {
            setTimeout(
                function () {
                    $(".form-btn").removeClass("disabled")
                    $(".blank-password").fadeOut(300)
                },1000
            )
            });
        } else {
            Request.post({
                url: '/u/log-in',
                data: {
                    email,
                    password,
                    type:0,
                }
            }).then(res => {
            $(".blank-acct").delay(1500).fadeIn(300,function () {
                setTimeout(
                    function () {
                        $(".form-btn").removeClass("disabled")
                        $(".blank-acct").fadeOut(300)
                    },3000
                )
            });
        });
      }
    };

    return (
        <div className='form-wrapper'>
                <div className='input-wrapper'>
                    <input placeholder={pageText.text.EMAILADDRESS} onChange={e => {
                        setEmail(e.target.value);
                    }} className='form-email'></input>
                </div>
                <div className='input-wrapper'>
                    <input placeholder={pageText.text.PASSWORD} type="password" onChange={e => {
                        setPassword(e.target.value);
                    }} className='form-password'></input>
                </div>
            <div className='form-btn' onClick={() => login8()}>{pageText.text.SUBMIT}</div>
            <div className='interested-in-our-membership' dangerouslySetInnerHTML={{ __html: pageText.text.INTEREST}}></div>
            <Link to="/membership" onClick={() => changeUrlMobile()}>
                <div className='form-btn member-btn'>
                    {pageText.text.MEMBERSHIP}
                </div>
            </Link>
        </div>
    )
}


class PageModel extends Component {
    componentWillMount() {
        jQuery(".slideMask").animate({
            left: '-100vw'
          },1200,"easeInCubic",function(){
            $(".slideMask").css("left","100vw")
          })
          setTimeout(() => {
            window.scrollTo(0,0)
          }, 900);
    }
  
    componentDidMount(){
        $(".collection").removeClass('active')
        $(".exclusive").removeClass('active')
        $(".discover").removeClass('active')

        window.history.scrollRestoration = 'manual'
    }
  
    componentDidUpdate(prevProps, prevState, snapshot){
      hideMask()
    }
    
    
    render() {
        return (
            <div className="container tac forms">
                <div className='blank-input blank-email'>{pageText.text.ERR_EMAILADDRESS}</div>
                <div className='blank-input blank-password'>{pageText.text.ERR_PASSWORD}</div>
                <div className='blank-input blank-acct'>{pageText.text.tipTxt}</div>
                <div className='blank-input wrong-email'>{pageText.text.ERR_EMAIL_FORMAT}</div>
                
                <div className='tac-menu'></div>
                <div className='tac-content'>
                    <div className='tac-content-section'>
                        <div className='tac-title'>{pageText.text.LOGIN}</div>
                    </div>
                    <FormBody></FormBody>
                </div>
            </div>
        );
    }
}
 
export default withRouter(PageModel);