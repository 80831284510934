
import './index.less';
import {Component} from 'react';
import $ from 'jquery';
import jQuery from 'jquery-easing';
import Detail from '../../component/detail'
import {withRouter} from 'react-router-dom'
import { products } from '../../component/products';
import { hideMask } from '../../component/cart';
import { changeUrl } from '../../common/changeUrl';
 
class PageCleanser extends Component {

    componentWillMount() {
        jQuery(".slideMask").animate({
          left: '-100vw'
        },1500,"easeInCubic",function(){
          $(".slideMask").css("left","100vw")
          $(".top-mask .App-header").css('background-color','white')
        })
      }

    componentDidMount(){
        if(!$(".collection").hasClass('active')){
            $(".collection").addClass('active')
        }
        $(".exclusive").removeClass('active')
        $(".discover").removeClass('active')
        window.history.scrollRestoration = 'manual'
    }
  
    componentDidUpdate(prevProps, prevState, snapshot){
      hideMask()
      changeUrl()
    }
    
    
    render() {
        return (
            <div className="container">
                <Detail product={products.EN.b}></Detail>
            </div>
        );
    }
}
 
export default withRouter(PageCleanser);