
import './index.less';

import {Component} from 'react';
import $ from 'jquery';
import jQuery from 'jquery-easing';
import { upperLaundry } from '../../common/utils'

import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
import { accept, checkCookie, changeUrl} from '../../common/changeUrl'

import banner1 from '../../common/images/discover/banner1.jpg'
import banner2 from '../../common/images/discover/banner2.jpg'
import banner3 from '../../common/images/discover/banner3.jpg'
import press1 from '../../common/images/discover/press1.png'
import press2 from '../../common/images/discover/press2.png'
import press3 from '../../common/images/discover/press3.png'
import press4 from '../../common/images/discover/press4.png'
import press5 from '../../common/images/discover/press5.png'

import esignature from '../../common/images/discover/e-sign.png'
import { hideMask } from '../../component/cart';

const pageText = {
  section1 : {
    cover: banner1,
    title: ["NIHPLOD"],
    context: `
      <p>La peau du dauphin a la capacité magique de se renouveler toutes les deux heures. Notre source d’inspiration est cet instinct animal de ‘l’inversion du temps’.</p>
      <p>La peau sera confrontée à divers problèmes causés par le temps, l’environment, la pression au travail et les mauvaises habitudes.</p>
      <p>Nous avons ainsi inversé les lettres du mot anglais "DOLPHIN", et avons obtenu NIHPLOD.</p>
      <p>Grâce à la biotechnologie et aux formules les plus avancées, nous ferons de notre mieux pour aider les gens à réaliser ‘l’inversion du temps’ dans les soins de la peau.</p>
    `,
    button: `<span class="upper-size-content none-logo">D</span>écouvrir plus`
  },
  section2 : {
    cover: banner2,
    title: ["NOTRE","MISSION"],
    context: `
      <p><span class="brand-name">NIHPLOD</span> a été créée en 2008. Nous utilisons une biotechnologie avancée pour offrir à nos clients une expérience de soins de la peau efficace, saine et raffinée.</p>
      <p><span class="brand-name">NIHPLOD</span> combine la technologie des liposomes et des nanoémulsions avec des ingrédients naturels soigneusement sélectionnés, à travers des essais cliniques et des recherches approfondies, pour améliorer les soins quotidiens de la peau.</p>
      <p>Notre objectif est de continuer à avancer pour aider les gens à améliorer leur qualité de vie.</p>
    `,
    sign: `<p><span class="first-letter">J</span>ohn Morrell, CEO</p>`,
    eSign: esignature
    
  },
  section3 : {
    cover: banner3,
    title: ["NOTRE","PHILOSOPHIE"],
    context: `
      <p><div class="title-sm"><span class="text-radio"></span>Des produits plus précieux</div>L’objectif de nos produits est d’avoir est d’avoir une absorption et un effet complets. Les produits sont constamment mis à jour et améliorés en collectant les meilleures matières et en les combinant avec la science et la technologie les plus avancées et efficaces.</p><p>
      <div class="title-sm"><span class="text-radio"></span>Une meilleure expérience</div>Nous vous fournissons les services les plus satisfaisants, confortables et professionnels à partir de canaux d’approvisionnement soigneusement sélectionnés et des services spécialisés.</p><p>
      <div class="title-sm"><span class="text-radio"></span>Un mode de vie plus actif</div>Nous recommandons un mode de vie sain, une activité sportive modérée, un régime raisonnable et un esprit équilibré.</p><p>
      <div class="title-sm"><span class="text-radio"></span>Plus grande responsabilité</div>Nous donnons <span class="upper-size none-logo">2%</span> du prix de chaque produit vendu à des organisations caritatives et à but non lucratif du monde entier, en particulier la Fondation des Nations Unies, la Fondation Suisse de la Philanthropie, etc.</p>
      
    `,
  },
  section4 : {
    cover: press5,
    title: ["L'INFLUENCE","DES MÉDIAS"],
    context: `
      <p><span class="brand-name">NIHPLOD</span>'s skincare programme - worth it<span class="question-mark">?</span></p>
      <p>Absolutely yes, it's expensive but this information is priceless and gives you a targeted way to fight ageing for life.<br />It's the most empowering "beauty treatment" I've had yet.</p>
      <p>— Robb Report</p>
    `,
    button: `<span class="upper-size-content none-logo">D</span>écouvrir plus`
  },
  section5 : {
    cover: [press4,press1,press2],
    quote: [
      `
      <div>To achieve the healthy, glowing skin, Scarlett Johansson worked with the luxury Monaco skincare line NIHPLOD to prep the skin.</div>
      `,
      `<div>NIHPLOD's step by step facial that focuses on brightening and diminishing pigmentation whilst balancing the skin.</div>
      `,
      `
      <div>With the help of new generation liposome technology, NIHPLOD solves the problem that skin can't really absorb skincare products.</div>
      `,
    ],
  }
}

class PageDiscover extends Component {
  
  componentWillMount() {
    jQuery(".slideMask").animate({
      left: '-100vw'
    },1500,"easeInCubic",function(){
      $(".slideMask").css("left","100vw")
    })
  }

  componentDidMount(){
    if(!$(".discover").hasClass('active')){
      $(".discover").addClass('active')
    }
    $(".collection").removeClass('active')
    $(".exclusive").removeClass('active')
    window.history.scrollRestoration = 'manual'
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    hideMask()
    changeUrl()
  }

  

  render (){
    return (
      <div className="container">
        <div className='content-section discover-section'>
          <div className='banner-d'>
            <img src={pageText.section1.cover} alt={"cover"}></img>
          </div>
          <div className='content right'>
            <div className='title'>
              <p>{pageText.section1.title[0]}</p>
            </div>
            <div className='context' dangerouslySetInnerHTML={{ __html: upperLaundry(pageText.section1.context) }}></div>
            <Link to="/faq" className="explore-more" onClick={() => {changeUrl("faq")}} dangerouslySetInnerHTML={{ __html: pageText.section1.button }}></Link>
          </div>
        </div>
        <div className='content-section discover-section establishment'>
          <div className='content left'>
          <div className='title'>
              <p>{pageText.section2.title[0]}</p>
              <p>{pageText.section2.title[1]}</p>
            </div>
            <div className='context' dangerouslySetInnerHTML={{ __html: upperLaundry(pageText.section2.context) }}></div>
            <img src={pageText.section2.eSign} className="esign"></img>
            <div className='context' dangerouslySetInnerHTML={{ __html: upperLaundry(pageText.section2.sign) }}></div>
          </div>
          <div className='banner-d'>
            <img src={pageText.section2.cover} alt={"cover"}></img>
          </div>
        </div>
        <div className='content-section discover-section'>
          <div className='banner-d'>
            <img src={pageText.section3.cover} alt={"cover"}></img>
          </div>
          <div className='content right'>
            <div className='title'>
              <p>{pageText.section3.title[0]}</p>
              <p>{pageText.section3.title[1]}</p>
            </div>
            <div className='context' dangerouslySetInnerHTML={{ __html: upperLaundry(pageText.section3.context) }}></div>
          </div>
        </div>
        <div className='content-section discover-section press'>
          <div className='content left'>
            <div className='title'>
              <p>{pageText.section4.title[0]}</p>
              <p>{pageText.section4.title[1]}</p>
            </div>
            <div className='context' dangerouslySetInnerHTML={{ __html: upperLaundry(pageText.section4.context) }}></div>
          </div>
          <div className='banner-d'>
            <img src={pageText.section4.cover} alt={"cover"} style={{objectPosition: "50% 50%", objectFit: "contain"}}></img>
          </div>
        </div>
        {/* <div className='content-section discover-section press-wall'>
          <div className='press-item'>
            <img src={press1} alt={"press"}></img>
            <div className='press-quote' dangerouslySetInnerHTML={{ __html: upperLaundry(pageText.section5.quote[0]) }}></div>
          </div>
          <div className='press-item'>
            <img src={press4} alt={"press"}></img>
            <div className='press-quote' dangerouslySetInnerHTML={{ __html: upperLaundry(pageText.section5.quote[1]) }}></div>
          </div>
          <div className='press-item'>
            <img src={press2} alt={"press"}></img>
            <div className='press-quote' dangerouslySetInnerHTML={{ __html: upperLaundry(pageText.section5.quote[2]) }}></div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default PageDiscover;
